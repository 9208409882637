<!--
 * @Description: 视频监控管理 视频监控 实时视频  realTimeVideoMonitor
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 11:46:36
 * @LastEditors: zhoucheng
-->
<template>
  <div class="body">
    <div class="left"
         :style="showRight?'width:80%;':'width:100%;'">
      <!-- 单屏 -->
      <div class="left-content"
           v-if="screenRadio==='oneScreen'"
           id="flv-app">
        <video controls
               muted
               id="videoElementOne">
        </video>
      </div>
      <!-- 2*2 -->
      <div class="left-content"
           v-if="screenRadio==='fourScreen'">
        <el-row class="fourOne">
          <el-col class="itemCol"
                  :span="12"
                  v-for="(item, index) in fourUrlList"
                  :key="index"
                  @click.native="handleClickFour(index)"
                  style="height: 50%">
            <video :ref="index"
                   class="video"
                   :id="index"
                   v-if="fourShow===1">
            </video>
            <el-row class="text"
                    :id="index"
                    v-if="fourShow===0">
              <span class="textContent"> 暂未选择设备 无监控数据</span>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <!-- 3*3 -->
      <div class="left-content"
           v-if="screenRadio==='nineScreen'"
           id="flv-app">
        <el-row class="nineOne">
          <el-col class="itemCol"
                  :span="8"
                  v-for="(item, index) in nineUrlList"
                  :key="index"
                  @click.native="handleClickNine(index)">
            <video :ref="index"
                   class="video"
                   :id="index"
                   v-if="nineShow===1"></video>
            <el-row class="text"
                    v-if="nineShow===0">
              <span class="textContent"> 暂未选择设备 无监控数据</span>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-row class="left-content-bottom">
        <el-radio-group v-model="screenRadio"
                        @change="handleChangeScreen">
          <el-radio label="oneScreen">单屏</el-radio>
          <el-radio label="fourScreen">2*2</el-radio>
          <el-radio label="nineScreen">3*3</el-radio>
        </el-radio-group>
      </el-row>
    </div>
    <!-- 折叠面板展开 -->
    <el-row>
      <i class="el-icon-caret-left"
         :style="showRight?'transform: rotate(180deg)':''"
         @click="showRight=!showRight"></i>
    </el-row>
    <el-row class="right"
            :style="showRight?'width:19%;':'width:0;'">
      <el-row>筛选面板</el-row>
      <!-- 街道、城镇 -->
      <el-row class="line">
        <el-select v-model="street"
                   placeholder="请选择街道、城镇"
                   @change="handleChangeStreet">
          <el-option v-for="item in streetList"
                     :key="item.value"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </el-row>
      <!-- 路段 -->
      <el-row class="line">
        <el-select v-model="road"
                   placeholder="请选择路段"
                   @change="queryRoadChange">
          <el-option v-for="item in roadList"
                     :key="item.value"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </el-row>
      <!-- 车行道、人行道 -->
      <el-row class="line">
        <el-radio-group v-model="roadRadio"
                        @change="getParkRoadChange">
          <el-radio label="carRoad">车行道</el-radio>
          <el-radio label="manRoad">人行道</el-radio>
        </el-radio-group>
      </el-row>
      <!-- 具体车行道停车路段 -->
      <el-row class="line"
              v-if="roadRadio==='carRoad'">
        <el-select v-model="carRoad"
                   placeholder="请选择车行道停车路段"
                   @change="handleChangeCarRoad">
          <el-option v-for="item in carRoadList"
                     :key="item.value"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </el-row>
      <!-- 具体人行道停车路段 -->
      <el-row class="line"
              v-if="roadRadio==='manRoad'">
        <el-select v-model="manRoad"
                   placeholder="请选择人行道停车路段">
          <el-option v-for="item in manRoadList"
                     :key="item.value"
                     :label="item.name"
                     :value="item.code">
          </el-option>
        </el-select>
      </el-row>
      <!-- 查询按钮 -->
      <!-- <el-row style="display:flex;margin-top:20px">
        <el-button type="primary">查 询</el-button>
        <el-button type="info">重 置</el-button>
      </el-row> -->
      <el-row style="background:#00487A;height:1px;width:100%;margin-top:20px"></el-row>
      <el-row class="line">
        摄像头信息
      </el-row>
      <!-- 摄像头列表 -->
      <el-row class="line">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  ref="tableRef"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-button type="text"
                           size="medium"
                           @click="handleClickMonitor(scope.row)">
                  <span style="color:#019fe4;float:left">实时播放</span>
                </el-button>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import flvjs from 'flv.js'
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      screenRadio: "oneScreen",// 屏幕数量选择 oneScreen fourScreen nineScreen
      fourUrlList: ["", "", "", ""],
      nineUrlList: ["", "", "", "", "", "", "", "", ""],
      flvPlayer: null,
      selectFour: null, // 2*2选中 
      selectNine: null, // 3*3选中
      fourShow: 0, // 四屏幕未选择
      nineShow: 0,// 九屏未选择
      showRight: true, // 右侧显示
      rotate: false,
      roadRadio: "carRoad", // 车行道 人行道选择
      street: "", // 街道、诚征
      streetList: [], // 街道下拉
      road: "", // 路段
      roadList: [], // 路段下拉
      carRoadList: [], // 选择的停车场
      manRoadList: [],
      carRoad: "",// 车行道绑定
      manRoad: "",// 人行道绑定
      parkSpaceId: '', // 车位号
      roadType: 1,//停车场类型
      parkCode: '', // 停车场编号
      videoListData: [],// 摄像头名称列表
      //分页
      paginationConfig: { layout: 'prev,pager,next', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 5,
      },
      pageNum: 1,
      pageSize: 5,
      pageTotal: 0,
      tableColumnList: [
        {
          prop: 'parkSpaceId',
          label: '车位号',
        }
      ],
      //表格内容
      tableList: {
        list: [],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryStreet()
    this.queryRoadChange(this.roadType)
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    //切换屏幕数量 oneScreen fourScreen nineScreen
    handleChangeScreen () {
      console.log(this.screenRadio, "screenRadio");
    },
    // 实时播放点击事件
    handleClickMonitor (row) {
      let info = {
        parkId: this.carRoad,
        parkSpaceId: row.parkSpaceId
      }
      if (this.screenRadio === 'oneScreen') {
        this.$realTimeVideoMonitor.getVideoUrl(info).then(res => {
          this.flvUrlOne = res
          if (flvjs.isSupported()) {
            var videoElementOne = document.getElementById('videoElementOne');
            var flvPlayer = flvjs.createPlayer({
              type: 'flv',
              url: this.flvUrlOne
            });
            flvPlayer.attachMediaElement(videoElementOne);
            flvPlayer.load();
            flvPlayer.play();
          }
        })
      }
      else if (this.screenRadio === 'fourScreen') {
        this.$realTimeVideoMonitor.getVideoUrl(info).then(res => {
          this.createFlvPlayerFour(this.selectFour, res)
        })
      }
      else if (this.screenRadio === 'nineScreen') {
        this.$realTimeVideoMonitor.getVideoUrl(info).then(res => {
          this.createFlvPlayerNine(this.selectNine, res)
        })
      }
    },
    // 点击2*2每项 
    handleClickFour (idx) {
      this.selectFour = this.$refs[idx][0]
    },
    //  2*2视频渲染
    createFlvPlayerFour (element, flvUrl) {
      if (flvjs.isSupported()) {
        this.fourShow = 1
        var flvPlayer = flvjs.createPlayer({
          type: 'flv',
          url: flvUrl
        });
        flvPlayer.attachMediaElement(element);
        flvPlayer.load();
        flvPlayer.play();
      }
    },
    // 点击3*3
    handleClickNine (idx) {
      this.selectNine = this.$refs[idx][0]
    },
    // 3*3视频渲染
    createFlvPlayerNine (element, flvUrlNine) {
      if (flvjs.isSupported()) {
        this.nineShow = 1
        var flvPlayer = flvjs.createPlayer({
          type: 'flv',
          url: flvUrlNine
        });
        flvPlayer.attachMediaElement(element);
        flvPlayer.load();
        flvPlayer.play();
      }
    },
    // 查看监控
    // handleClickMonitor (row) {
    //   console.log(row.parkSpaceId);
    // },
    //获取所属街道
    queryStreet () {
      let info = {
        "columnName": [
          "region_code",
          "region_name"
        ],
        "tableName": "tb_region"
      }
      this.$queryDict.queryDict(info).then(res => {
        this.streetList = res.resultEntity
      })
    },
    //获取所属街道路段  
    handleChangeStreet (val) {
      this.tableList.list = []
      this.paginationConfig.total = 0
      this.pageNum = 1
      this.road = ''
      this.carRoad = ''
      this.manRoad = ''
      let info = {
        "columnName": ["road_code", "road_name"],
        "tableName": "tb_road",
        "whereStr": [
          {
            "colName": "region_code",
            "value": val
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.roadList = res.resultEntity
      })
    },
    // 选择路段变化
    queryRoadChange (val) {
      this.parkCode = val
      this.getParkRoadChange(this.roadRadio)
    },
    // radio切换事件
    getParkRoadChange (val) {
      this.tableList.list = []
      this.paginationConfig.total = 0
      this.pageNum = 1
      this.roadRadio = val
      if (this.roadRadio === "carRoad") {
        this.roadType = 1
      } else if (this.roadRadio === "manRoad") {
        this.roadType = 2
      }
      let info = {
        "columnName": ["park_id", "park_name"],
        "tableName": "tb_park",
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": this.roadType //这个是停车场类型 1是路内2是路外
          },
          {
            "colName": "road_code",
            "value": this.parkCode  //这个是路段的code
          },
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then(res => {
        if (this.roadType === 1) {
          this.carRoadList = res.resultEntity
          if (this.carRoad) {
            this.handleChangeCarRoad(this.carRoad)
          }
        }
        else if (this.roadType === 2) {
          this.manRoadList = res.resultEntity
          if (this.manRoad) {
            this.handleChangeCarRoad(this.manRoad)
          }
        }
      })
    },
    // 根据车行道停车场编号查询车位
    handleChangeCarRoad (val) {
      let info = {
        parkId: val,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      console.log(info);
      this.$realTimeVideoMonitor.queryListByPage(info).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageNum = change.pageNum
      if (this.carRoad) {
        this.handleChangeCarRoad(this.carRoad)
      } else if (this.manRoad) {
        this.handleChangeCarRoad(this.manRoad)
      }
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
/deep/ .totalText {
  width: 100% !important;
}
/deep/ .el-pagination {
  width: 100% !important;
  margin-top: -10px !important;
}
.body {
  width: 100%;
  height: 100%;
  display: flex;
  .left {
    width: 80%;
    height: 100%;
    .left-content {
      width: 96%;
      margin-left: 2%;
      height: 94%;
      #videoElementOne {
        width: 96%;
        margin-left: 2%;
        height: 100%;
      }
      .fourOne {
        height: 100%;
        width: 100%;
        .itemCol {
          width: 48%;
          height: 100%;
          margin-left: 1.5%;
          margin-top: 10px;
          background: #000000;
          .video {
            width: 100%;
            height: 100%;
          }
          .text {
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 386px;
            .textContent {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .nineOne {
        height: 100%;
        width: 100%;
        .itemCol {
          width: 30%;
          height: 31%;
          margin-left: 1.5%;
          margin-top: 10px;
          background: #000000;
          .video {
            width: 100%;
            height: 100%;
          }
          .text {
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 239px;
            .textContent {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .left-content-bottom {
      width: 100%;
      height: 3%;
      padding-top: 1%;
      display: flex;
      justify-content: space-between;
    }
  }
  .right {
    width: 20%;
    overflow: hidden;
    .mid {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .line {
      width: 100%;
      margin-top: 20px;
      button {
        width: 100%;
        color: white;
      }
    }
  }
  .el-icon-caret-left {
    height: 100%;
    width: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>